import * as React from 'react';
import {Locale, ModalHandler} from "ias-lib";
import {Table} from "antd";
import moment from "moment";
import {useQuery} from "@apollo/react-hooks";
import {gql} from "apollo-boost";
import AlarmLogModal from "./AlarmLogModal";

import './Alarms.css';
import Loading from "../Loading";
import {AuthContext} from "ias-auth-web";
import {useBenchContext} from "../../context/BenchContext";

type Props = {
    bench: Bench
}

const ALARMS_LOGS = gql`
  query alarmLogs ($id: Int!){
    alarmLogs(benchId: $id) {
      id
      num
      message
      isAcknowledgeable
      isAlarmOnUp
      createdAt
      acknowledgedAt
      acknowledgedBy {
        id
      }
      disappearedAt
      notificationEvents {
        id
      }
      state
    }
  }
`;

const Alarms: React.FunctionComponent<Props> = ({bench}) => {
    const {user} = AuthContext.useAuthContext();
    const {currentBench} = useBenchContext();
    const { loading, error, data } = useQuery(ALARMS_LOGS, {
        variables: {
            id: bench.id,
        },
        pollInterval: 1500,
    });

    if (error || loading) {
        return (
            <Loading/>
        );
    }
    const columns = [{
        title: Locale.trans('alarm.num_io'),
        dataIndex: 'num',
        key: 'numberPin',
        sorter: (a: AlarmLog, b: AlarmLog) => { return a.num - b.num},
        filters: data.alarmLogs.reduce((total: number[], alarm: AlarmLog) => {
            if (total.indexOf(alarm.num) !== -1) {
                return total;
            }
            total.push(alarm.num);
            return total;
        }, []).map((num: number) => ({
            text: num,
            value: num
        })),
        onFilter: (value: number, record: AlarmLog) => record.num == value,
    }, {
        title: Locale.trans('alarm.message'),
        dataIndex: 'message',
        key: 'message',
    }, {
        title: Locale.trans('alarm.date'),
        dataIndex: 'createdAt',
        key: 'date',
        render: (date: string) => {
            return moment(date).fromNow();
        },
        sorter: (obj: AlarmLog, objB: AlarmLog) => {
            const mdate = moment(obj.createdAt);
            const mdateB = moment(objB.createdAt);
            if (mdate.isAfter(mdateB)) {
                return 1;
            }
            if(mdate.isBefore(mdateB)) {
                return -1;
            }
            return 0;
        },
        defaultSortOrder: "descend" as 'descend'
    }, {
        title: Locale.trans('alarm.status'),
        dataIndex: 'state',
        key: 'status',
        sorter: (a: AlarmLog, b: AlarmLog) => { return a.num - b.num},
        filters: [
            "disappeared",
            "acknowledged",
            "ended",
            "current",
        ].map((num: string) => ({
            text: Locale.trans(`alarm.state.${num}`),
            value: num
        })),
        onFilter: (value: string, record: AlarmLog) => record.state === value,
        render: (value: string) => Locale.trans(`alarm.state.${value}`),
        defaultFilteredValue: ["current", "disappeared", "acknowledged"],
    }];


    return (
        <div className={"alarms_part"}>
            <h3>{Locale.trans('alarm.title')}</h3>
            <Table
                dataSource={data.alarmLogs}
                columns={columns}
                rowKey={(record: AlarmLog) => "" + record.id}
                pagination={{
                    pageSize: 20
                }}
                onRow={(record: AlarmLog, index: number) => ({
                    onClick: () => {
                        ModalHandler.open(AlarmLogModal, {alarm: record, user, currentBench, isAdmin: false})
                    }
                })}
                rowClassName={(record: AlarmLog) => `alarm ${record.state}`}
            >

            </Table>
        </div>
    );
}
export default Alarms;
