import {useEffect, useState} from "react";

function updateStyle(isBlack: boolean) {
    if(isBlack) {
        root.style.setProperty('--primary-color', "#00677F");
        root.style.setProperty('--background-color', "#2b2b2b");
        root.style.setProperty('--back-background-color', "#3c3f41");
        root.style.setProperty('--border-color', "#fdfdfd");
        root.style.setProperty('--font-color', "#fdfdfd");
        root.style.setProperty('--menu-font-color', "#fdfdfd");
    } else {
        root.style.setProperty('--primary-color', "#fff498ff");
        root.style.setProperty('--background-color', "#fdfdfd");
        root.style.setProperty('--back-background-color', " #f0f2f5");
        root.style.setProperty('--border-color', "#c8c8c8");
        root.style.setProperty('--font-color', "#c8c8c8");
        root.style.setProperty('--menu-font-color', "#1E2942");
    }
}

function init() {
    updateStyle(localStorage.getItem('theme') === 'black');
}
let root = document.documentElement;
function useThemeHandler() {
    const [isBlack, setIsBlack] = useState(false);

    useEffect(() => {
        if(!localStorage.getItem('theme')) {
            setIsBlack(false);
            localStorage.setItem('theme', 'white');
        } else if (localStorage.getItem('theme') === 'white') {
            setIsBlack(false);
        } else {
            setIsBlack(true);
        }
    });
    useEffect(() => {
        updateStyle(isBlack);
    }, [isBlack]);

    const toggle = () => {
        setIsBlack(!isBlack);
        if((!isBlack) === true) {
            localStorage.setItem('theme', 'black');
        } else {
            localStorage.setItem('theme', 'white');
        }
    };
    return {
        isBlack,
        toggle,
    }
}
export {
    useThemeHandler,
    init,
};
