import React from 'react';
import {Locale, ModalHandler} from "ias-lib";
import {Button, Icon} from "antd";
import {default as MenuBase, ClickParam} from "antd/lib/menu";
import {useLocation, useHistory} from 'react-router-dom';
import UserModal from "./views/Settings/UserModal";
import {AuthContext, LoginActions} from "ias-auth-web";

type Props = {

}

const Menu: React.FunctionComponent<Props> = () => {
    const {user} = AuthContext.useAuthContext();
    const location = useLocation();
    const history = useHistory();

    const selectedMenu = location.pathname;

    const handleMenuClick = (e: ClickParam) => {
        if(e.key[0] !== '/') {
            return;
        }
        if (e.key !== location.pathname) {
            history.push(e.key);
        }
    };

    return (
        <MenuBase
            theme="dark"
            onClick={handleMenuClick}
            selectedKeys={[selectedMenu]}
            mode="inline"
        >
            <MenuBase.Item key="/">
                <Icon type="home" />
                <span>{Locale.trans('pages.alarmes')}</span>
            </MenuBase.Item>
            <MenuBase.Item key="/camera">
                <Icon type="camera" />
                <span>{Locale.trans('pages.camera')}</span>
            </MenuBase.Item>
            <MenuBase.Item key="/configuration">
                <Icon type="setting" />
                <span>{Locale.trans('pages.settings')}</span>
            </MenuBase.Item>
            <MenuBase.Item key="/guides" onClick={() => {
                let a = document.createElement('a');
                let index = Math.random() * 46;
                a.href = "https://m.oranotn.ias-ingenierie.com/dl/Installation.pdf?v=" + index;
                a.download = 'Installation' + index + '.pdf';
                a.click();
            }}>
                <Icon type="book" />
                <span>{Locale.trans('pages.guide')}</span>
            </MenuBase.Item>
            <MenuBase.Item key="user" onClick={() => ModalHandler.open(UserModal, {user, isNew: false})}>
                <Icon type="user" />
                {Locale.trans('pages.profile')}
            </MenuBase.Item>
            <MenuBase.Item key="user" onClick={() =>{
                LoginActions.logoutUser();
                history.push('/login');
            }}>
                <Icon type="logout" />
                {Locale.trans('login.logOut.button')}
            </MenuBase.Item>

        </MenuBase>
    )
};

export default Menu;
