import {useEffect, useState} from "react";


export function useSidebar() {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    useEffect(() => {
        setCollapsed( window.innerWidth <= 900 || localStorage.getItem('sidebar-collapsed') === 'true');
    }, []);

    const toggleCollapse = () => {
        console.log(collapsed);
        setCollapsed(!collapsed);
        localStorage.setItem('sidebar-collapsed', collapsed.toString());
    };

    return {
        collapsed,
        toggleCollapse
    }
}
