import * as React from 'react';
import {Input, Modal, Form, Button} from "antd";
import {Locale, ModalHandler, ToastActions} from "ias-lib";
import {useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useMutation} from "@apollo/react-hooks";
import ChangePasswordModal from "./ChangePasswordModal";

type Props = {
    visible: boolean;
    user?: User;
    modalId: number;
    onCancel?: () => void;
    isNew: boolean;
}

type Fields = {
    firstName: string,
    phone: string,
    lastName: string,
    email: string,
    plainPassword?: string,
}
const defaultFields: Fields = {
    firstName: '',
    phone: '',
    lastName: '',
    email: '',
    plainPassword: '',
};


const ADD_USER = gql`
  mutation addUser ($user: AddUserInput!) {
     addUser (input: $user) {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation updateUser ($user: UserInput!, $id: Int!) {
     updateUser (input: $user, id: $id) {
      id
      firstName
      lastName
      phone
      email
    }
  }
`;

const UserModal: React.FunctionComponent<Props> = ({visible, user, isNew, onCancel}) => {
    const [updateUser, {data}] = useMutation(UPDATE_USER);
    const [addUser] = useMutation(ADD_USER);
    const [fields, setFields] = useState<Fields>({...defaultFields});
    useEffect(() => {
        if(!user || isNew) {
            setFields({...defaultFields})
        } else {
            setFields({
                // @ts-ignore
                    firstName: user.first_name || user.firstName,
                // @ts-ignore
                    phone: user.phone_number || user.phoneNumber,
                // @ts-ignore
                    lastName: user.last_name || user.lastName,
                // @ts-ignore
                    email: user.email,
            });
        }
    }, [user, isNew]);
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={750}
            title={Locale.trans(isNew? 'user.add.title' : 'user.view.title')}
            onOk={() => {
                if (isNew) {
                    addUser({
                        variables: {
                            user: fields
                        }
                    })
                        .then(() => {
                            ToastActions.createToastSuccess(Locale.trans("user.added"));
                            onCancel && onCancel();
                        })
                        .catch(() => {
                            ToastActions.createToastError(Locale.trans("error"));
                        });
                } else {
                    updateUser({
                        variables: {
                            user: fields,
                            id: user && user.id,
                        }
                    })
                        .then(() => {
                            ToastActions.createToastSuccess(Locale.trans("user.changed"));
                            onCancel && onCancel();
                        })
                        .catch(() => {
                            ToastActions.createToastError(Locale.trans("error"));
                        });
                }
            }}
        >
            <Form.Item label={ Locale.trans("user.firstName") } hasFeedback>
                <Input value={fields.firstName} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        firstName: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label={ Locale.trans("user.name") } hasFeedback>
                <Input value={fields.lastName} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        lastName: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label={ Locale.trans("user.phone") } hasFeedback>
                <Input value={fields.phone} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        phone: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item label={ Locale.trans("user.mail") } hasFeedback>
                <Input value={fields.email} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        email: value,
                    }))
                }} />
            </Form.Item>
            {!isNew && (<Button type="primary" onClick={() => {
                ModalHandler.open(ChangePasswordModal, {user});
            }}>{ Locale.trans("user.change_password") }</Button>
            )}
            {isNew && (
                <Form.Item label="Mot de passe" hasFeedback>
                    <Input type="password" value={fields.plainPassword} onChange={(el) => {
                        const value = el.target.value;
                        setFields(fields => ({
                            ...fields,
                            plainPassword: value,
                        }))
                    }} />
                </Form.Item>
            )}
        </Modal>
    );
}
export default UserModal;
