export default {
    Trans: {
        pages: {
            alarmes: "Alarmes",
            camera: 'Caméra',
            settings: 'Configuration',
            profile: 'Profil',
            home: 'Alarmes',
            admin: 'Administration',
            affectation: 'Affectation',
            guide: "Guide",
        },
        login: {
            username: "Identifiant",
            forgottenPassword: "Mot de passe oublié",
            password: {
                _: "Mot de passe",
                forgotten: "Mot de passe oublié",
            },
            logOut:{
                button: 'Se déconnecter',
            },
            logIn: {
                _: "Connection",
                button: "Se connecter",
            }
        },
        admin: {
            logIn: "Connection administrateur",
            logOut: "Déconnection administrateur",
            connected: "Vous êtes connecté en tant qu'administrateur",
            disconnected: "Vous êtes déconnecté de l'administration",
        },
        theme: {
            black: "Thème sombre",
            white: "Thème clair",
        },
        error: "Une erreur est survenue",
        save: "Enregistrer",
        user: {
            title: "Utilisateurs",
            add: "Ajouter un utilisateur",
            added: "Utilisateur ajouté",
            changed: "Utilisateur modifié",
            firstName: "Prénom",
            name: "Nom",
            mail: "Email",
            phone: "Téléphone",
            view: {
                title: "Édition de l'utilisateur"
            },
            select: "Sélectionner un utlisateur",
            change_password: "Changer le mot de passe",
        },
        bench: {
            title: "Bancs",
            add: "Ajouter un banc",
            name: "Nom",
            affected_user: 'Utilisateur affecté',
            view: {
                title: "Édition du banc"
            },
            state: {
                last_updated: 'Dernière mise à jour: __DATE__'
            }
        },
        parameters: "Paramètres",
        alarm: {
            title: "Alarmes",
            num_io: "Num ES",
            message: "Message",
            date: "Date",
            status: "Statut",
            state: {
                disappeared: "Disparue",
                acknowledged: "Acquittée",
                ended: "Terminée",
                current: "En cours",
            },
            parametrage: "Paramétrage des alarmes",
            notifs: "Notifications",
            acknowledge: "Acquitter",
            acknowledged: {
                error: "Une erreur est survenue. L'alarme n'a pas été acquittée.",
            },
            log: {
                title: "Journal d'alarme",
            },
            configuration: {
                title: "Paramétrage d'une alarme",
                select_io: "Sélectionner une Entrée/Sortie",
                select_new_action: "Ajouter une nouvelle action",
                message: "Message d'alarme",
                alert: {
                    select_user: "Sélectionner un utilisateur",
                    default_user: 'Utilisateur affecté',
                    type: {
                        call: "Appel",
                        notification: 'Notification',
                        sms: 'SMS',
                        mail: 'Email',
                    }
                },

            },
            onUp: "Cette alarme est créée sur un front montant",
            isAcknowledgeable: "Cette alarme peut être acquittée",
        },
        camera: {
            take_screenshot: "Prendre une capture d'écran",
        }
    }
}
