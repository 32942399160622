import * as React from 'react';
import {Avatar, Icon, Layout as LayoutBase, Tooltip} from "antd";
import {Locale } from "ias-lib";
import { useState} from "react";
import {useBenchContext} from "../context/BenchContext";
import useInterval from "../hooks/useInterval";
import {StateBench} from "../services/StateBench";
import moment from "moment";

import './Header.css';
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";


const GET_CURRENT_ALARMS = gql`
  query currentAlarms ($bench: Int!){
    currentAlarms(benchId: $bench)
  }
`;

type Props = {
    toggleCollapse: () => void;
    collapsed: boolean;
}

const Header: React.FunctionComponent<Props> = ({toggleCollapse, collapsed}) => {
    const [state, setState] = useState<{state: boolean, last_update:any}>();

    const {currentBench} = useBenchContext();
    const { loading, error, data } = useQuery(GET_CURRENT_ALARMS,  {
        variables: {
            bench: currentBench && currentBench.id
        },
        pollInterval: 1000,
    });

    useInterval(() =>  {
        if(currentBench) {
            StateBench.getState(currentBench.id)
                .then((response) => {
                    if (!state || response.state !== state.state || state.last_update !== response.last_update) {
                        setState(response);
                    }
                })
        }
    },  5000);


    return (
        <LayoutBase.Header>
            <div
                className="sider-toggle-menu-btn"
                role="button"
                tabIndex={0}
                onClick={toggleCollapse}
                onKeyPress={toggleCollapse}
            >
                <Icon type={collapsed ? 'menu-unfold' : 'menu-fold'} />
            </div>
            <div className="bench" style={{ marginLeft: "5%" }}>
                {currentBench && (
                    <Tooltip
                        placement="bottom"
                        title={Locale.trans('bench.state.last_updated', {DATE: state ? moment(state.last_update).format('dd HH:mm:ss') : 'inconnue'})}
                    >
                        <span className={`bench-state ${state && state.state}`} />
                        {currentBench.name}
                    </Tooltip>
                )}
            </div>
                {!loading && !error && data.currentAlarms !== 0 && (
                    <Avatar size="large" className="alert-bell" style={{
                        position: "absolute",
                        right: "16px",
                        marginTop: "10px",
                        minWidth: "40px",
                        minHeight: "40px",
                        backgroundColor: "transparent",
                        animationName: "blinking",
                        animationIterationCount: "infinite",
                        animationTimingFunction: "cubic-bezier(.5, 0, 1, 1)",
                        animationDuration: "1.7s",
                    }}>
                        <Icon type="bell" />
                    </Avatar>
                )}
        </LayoutBase.Header>
    );
}
export default Header;
