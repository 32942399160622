import * as React from 'react';
import { Layout as LayoutBase} from "antd";
import Sider from "./components/Sider";
import {useSidebar} from "./hooks/useSidebar";
import {Link,  Switch, Route} from "react-router-dom";
import { Locale } from "ias-lib";
import Menu from "./Menu";
import Alarmes from "./views/Alarmes";
import Camera from "./views/Camera";
import {BenchContextElement} from "./context/BenchContext";
import Header from "./views/Header";

import './Layout.css';
import AlarmesConfiguration from "./views/AlarmesConfiguration";

export const Layout = () => {
    const {collapsed, toggleCollapse} = useSidebar();

    const smallLogoUrl = 'images/logo-orano.png';

    return (
        <BenchContextElement>
        <LayoutBase
            className={collapsed ? 'sidebar-collapsed' : ''}
            style={{ minHeight: '100vh' }}
        >
            <Sider
                collapsed={collapsed}
                onCollapse={() => toggleCollapse()}
                showMask={window.innerWidth <= 900}
            >
                <Link className="sider-home-link" to="/">
                    <div className="logo">
                        <img
                            className="logo-icon"
                            alt={Locale.trans('pages.home')}
                            src={smallLogoUrl}
                        />
                    </div>
                </Link>
                <Menu />
            </Sider>
            <LayoutBase>
                <Header
                    toggleCollapse={toggleCollapse}
                    collapsed={collapsed}
                />
                <LayoutBase.Content>
                    <Switch>
                        <Route exact path="/" component={Alarmes} />
                        <Route exact path="/camera" component={Camera} />
                        <Route exact path="/configuration" component={AlarmesConfiguration} />
                    </Switch>
                </LayoutBase.Content>
            </LayoutBase>
        </LayoutBase>
        </BenchContextElement>
    )
};
