import {useEffect, useRef} from 'react'
import * as React from 'react'
import PhotoSphereViewer from "photo-sphere-viewer";
import Hls from "hls.js";
import "../../node_modules/photo-sphere-viewer/dist/photo-sphere-viewer.min.css";
import {Button} from "antd";
import {Locale} from "ias-lib";
import flvjs from "flv.js";
import FlvJs from "flv.js";


type Props = {
    src: string
}

export default function SphereComponent(props: Props) {
    const sphereElementRef = useRef<HTMLDivElement|null>(null);
    const { src } = props;
    const videoRef = useRef<HTMLVideoElement|null>(null);

    useEffect(() => {
        var video = document.createElement('video');
        let flvPlayer: FlvJs.Player|null  = null;
        if(flvjs.isSupported()) {
            flvPlayer = flvjs.createPlayer({ type: "flv", url: src });
            flvPlayer.attachMediaElement(video);
            flvPlayer.load();
            flvPlayer.play();
        } else if(Hls.isSupported()) {

            console.log("hls");
            var hls = new Hls();
            hls.loadSource(src);
            hls.attachMedia(video);
            hls.on(Hls.Events.MANIFEST_PARSED,function()
            {
                video.play();
            });
        } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
            video.src = src;
            video.addEventListener('canplay',function() {
                video.play();
            });
        }

        videoRef.current = video;
        const spherePlayerInstance = PhotoSphereViewer({
            container: sphereElementRef.current,
            panorama: video,
            navbar: [
                'autorotate',
            ]
        });

        return () => {
            if(flvPlayer) {
                flvPlayer.unload();
                flvPlayer.detachMediaElement();
                flvPlayer.destroy();
            }

            spherePlayerInstance.destroy();
        };
    }, [src]);

    function test () {
        if(currentCanvas.current == null) return;
        //html2canvas(videoRef.current).then(function(canvas: any) {
        let a = document.createElement('a');
        a.href = currentCanvas.current.toDataURL("image/jpeg").replace("image/jpeg", "image/octet-stream");
        let date = new Date();
        a.download = 'ScreenShot' + date.getTime() + '.jpg';
        a.click();
        //});
    }

    const currentCanvas = useRef<HTMLCanvasElement|null>(null);

    useEffect(() => {
        const canvas = currentCanvas.current;
        if(canvas == null) return;
        var ctx = canvas.getContext('2d');
        var video = videoRef.current;
        if(video == null) return;
        if(ctx == null) return;

// set canvas size = video size when known
        video.addEventListener('loadedmetadata', function() {
            if(video == null) return;
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;
        });
        video.addEventListener('play', function() {
            const $this = this;
            (function loop() {
                //if (!$this.paused && !$this.ended) {
                // @ts-ignore
                ctx.drawImage($this, 0, 0);
                setTimeout(loop, 1000 / 30); // drawing at 30fps
                //}
            })();
        });
    }, [videoRef, currentCanvas]);


    return (
        <>
            <Button style={{marginBottom: "1%"}} icon="camera" onClick={() => test()}>
                {Locale.trans('camera.take_screenshot')}
            </Button>
            <div style={{ width: "100%", height: "675px"}} ref={sphereElementRef}/>
            <canvas ref={currentCanvas} style={{display: "none"}}/>
        </>
    );
}
