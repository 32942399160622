import * as React from 'react';
import {useMutation} from "@apollo/react-hooks";
import {useState} from "react";
import {useEffect} from "react";
import {gql} from "apollo-boost";
import {Form, Input, Modal} from "antd";
import {Locale, ToastActions} from "ias-lib";

type Props = {
    visible: boolean;
    user?: User;
    modalId: number;
    onCancel?: () => void;
}
type Fields = {
    new: string,
    confirm: string
}
const defaultFields = {
    new: '',
    confirm: ''
}

const CHANGE_PASSWORD = gql`
  mutation changePassword ($id: Int!, $password: String!) {
     changePassword (id: $id, newPassword: $password ) 
  }
`;


const ChangePasswordModal: React.FunctionComponent<Props> = ({visible, user, onCancel}) => {
    const [changePassword] = useMutation(CHANGE_PASSWORD);
    const [fields, setFields] = useState<Fields>({...defaultFields});
    const [errors, setErrors] = useState<Fields>({...defaultFields})
    useEffect(() => {
        setFields({...defaultFields})
    }, [user]);
    return (
        <Modal
            visible={visible}
            onCancel={onCancel}
            width={750}
            title={Locale.trans('user.change_pass.title')}
            onOk={() => {
                const errors = {...defaultFields};
                let error = false;
                if(fields.new != fields.confirm) {
                    errors.confirm = 'Les deux champs ne sont pas identiques';
                    error = true;
                }
                if(fields.new.length < 6) {
                    errors.new = 'Le password doit faire au minimum 6 caractères';
                    error = true;
                }
                setErrors(errors);
                if(!error && user) {
                    changePassword({
                        variables: {
                            id: user.id,
                            password: fields.new,
                        }
                    }).then((data) => {
                        if(data.data.changePassword) {
                            ToastActions.createToastSuccess("Le mot de passe à été modifié.");
                            onCancel && onCancel();
                        } else {
                            ToastActions.createToastError("Une erreur est survenue.");
                        }
                    }).catch((e) => {
                        ToastActions.createToastError("Une erreur est survenue.");
                    })
                }
            }}
        >
            <Form.Item
                label="Nouveau mot de passe"
                hasFeedback
                validateStatus={errors.new === '' ? '' : 'error' }
                help={errors.new}
            >
                <Input type="password" value={fields.new} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        new: value,
                    }))
                }} />
            </Form.Item>
            <Form.Item
                label="Confirmation"
                hasFeedback
                validateStatus={errors.confirm === '' ? '' : 'error' }
                help={errors.confirm}
            >
                <Input type="password" value={fields.confirm} onChange={(el) => {
                    const value = el.target.value;
                    setFields(fields => ({
                        ...fields,
                        confirm: value,
                    }))
                }} />
            </Form.Item>
        </Modal>
    );
}
export default ChangePasswordModal;
