import * as React from 'react';
import {Col, Row} from "antd";

import './Alarms/Alarms.css';
import Alarms from "./Alarms/Alarms";
import {useBenchContext} from "../context/BenchContext";
import Loading from "./Loading";

type Props = {}

const Alarmes: React.FunctionComponent<Props> = () => {
    const {currentBench} = useBenchContext();
    if(currentBench === null) {
        return (
            <Loading/>
        );
    }
    return (
        <Row gutter={12} className={"alarms"}>
            <Alarms bench={currentBench}/>
        </Row>
    );
}
export default Alarmes;
