import * as React from 'react';
import {useContext, useEffect, useState} from "react";
import {gql} from "apollo-boost";
import {useQuery} from "@apollo/react-hooks";

type Props = {}

const BenchContext = React.createContext<Context>({
    benches: [],
    currentBench: null,
    changeCurrentBench: () => {},
});

type Context = {
    benches: Bench[],
    currentBench: Bench|null,
    changeCurrentBench: (arg: Bench) => void,
}

const GET_BENCHES = gql`
  {
    benches {
        id
        name
        configuration {
            pollingFrequency
            errorThreshold
            nbInputsTOR
            streamingKey
            streamingURL
        }
        affectedTo {
            id
            email
        } 
    }
  }
`;


const BenchContextElement: React.FunctionComponent<Props> = ({children}) => {
    const [benches, setBenches] = useState<Bench[]>([]);
    const [currentBench, setCurrentBench] = useState<Bench|null>(null);
    const { loading, error, data } = useQuery(GET_BENCHES);

    useEffect(() => {
        if(loading || error) {
            setBenches([]);
        } else {
            setBenches(data.benches);
        }
    }, [loading, error, data]);
    useEffect(() => {
        if(!currentBench && benches.length !== 0) {
            setCurrentBench(benches[0]);
        }
    }, [benches, currentBench]);

    const changeCurrentBench = (bench: Bench) => {
        setCurrentBench(bench);
    };

    return (
        <BenchContext.Provider value={{
            benches,
            currentBench,
            changeCurrentBench,
        }}>
            {children}
        </BenchContext.Provider>
    );
};

const useBenchContext = () => {
    return useContext(BenchContext);
};

export {
    BenchContextElement,
    useBenchContext,
};
